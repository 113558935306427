<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="query.search"
          placeholder="Поиск"
          :valueNames="['name']"
          @querySearch="querySearch"
          @handleFindItems="getBrandsList"
          @selectItem="handleUpdateBrand($event.id)"
          @input="query.search = $event"
        />
        <el-button
          :class="$style.searchButton"
          type="primary"
          @click="getBrandsList"
        >
          Показать
        </el-button>
      </div>

      <el-button type="primary" @click="handleCreateBrand"
        >Создать бренд</el-button
      >
    </div>
    <el-table :data="brands" stripe>
      <el-table-column prop="name" label="Название" sortable />
      <el-table-column prop="slug" label="Имя в url" />
      <el-table-column width="100">
        <template slot-scope="scope">
          <ActionButtons
            editModal
            @delete="deleteBrand(scope?.row)"
            @edit="handleUpdateBrand(scope?.row?.id)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPage > 1"
      background
      layout="prev, pager, next"
      :page-size="query.limit"
      :total="count"
      :current-page.sync="query.page"
      :class="$style.pagination"
      @current-change="getBrandsList"
    />
    <el-dialog
      :visible.sync="isModalOpen"
      show-close
      :class="$style.dialog"
      :title="
        currentModalType === $options.MODAL_TYPES.CREATE
          ? 'Создание бренда'
          : 'Редактирование бренда'
      "
      :before-close="handleCloseForm"
    >
      <el-form
        ref="form"
        :model="form"
        :class="$style.form"
        label-position="left"
        label-width="12.5rem"
        :rules="rules"
      >
        <el-form-item label="Название" prop="name">
          <el-input
            v-model="form.name"
            :class="$style.input"
            @focus="!form.slug ? handleNameInput(form.name) : ''"
          />
        </el-form-item>
        <el-form-item label="Имя в url" prop="slug">
          <el-input v-model="form.slug" :class="$style.input" />
        </el-form-item>
        <SeoBlock
          is-with-keywords
          is-add-event
          has-set-default-flag
          :setDefault.sync="form.seo.setDefault"
          :title.sync="form.seo.title"
          :description.sync="form.seo.description"
          :og-title.sync="form.seo.ogTitle"
          :og-description.sync="form.seo.ogDescription"
          :og-image.sync="form.seo.ogImage.origin"
          :keywords.sync="form.seo.keywords"
          :class="$style.seo"
        />
      </el-form>
      <el-button
        type="primary"
        :class="$style.createButton"
        @click="submitForm"
        >{{
          currentModalType === $options.MODAL_TYPES.CREATE
            ? 'Создать'
            : 'Редактировать'
        }}</el-button
      >
    </el-dialog>
  </div>
</template>
<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
import SeoBlock from '@/components/moleculs/SEO.vue'

import { transliterate } from '@/helpers/slug'
import { MODAL_TYPES, SORTING_TYPES } from '@/constants/common'
import { SEO_TYPES } from '@/constants/seo'

export default {
  components: { ActionButtons, Autocomplete, SeoBlock },
  MODAL_TYPES,
  SORTING_TYPES,
  SEO_TYPES,
  mixins: [notifications],
  data() {
    return {
      isModalOpen: false,
      currentModalType: MODAL_TYPES.CREATE,
      brands: [],
      count: 0,
      currentBrandId: '',
      form: {
        name: '',
        slug: '',
        seo: {
          setDefault: false,
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: {
            id: null,
            caption: '',
            origin: '',
            order: 1,
          },
          keywords: '',
        },
      },
      query: {
        page: 1,
        limit: 20,
        orderBy: 'name',
        orderDir: SORTING_TYPES.ASC,
        search: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          keywords: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: {
            origin: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'change',
              },
            ],
          },
        },
      },
    }
  },
  computed: {
    totalPage() {
      return Math.ceil(this.count / this.query.limit)
    },
  },
  async created() {
    await this.getBrandsList()
  },
  methods: {
    async getBrandsList() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.BrandsActions.getList(this.query)

      loading.close()

      if (error) return

      this.brands = value?.data
      this.count = value?.meta?.count
    },
    async getBrand(id) {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.BrandsActions.getOne(id)

      loading.close()

      if (error) {
        this.showNotification('Ошибка получения бренда', 'error')
      }

      this.form = {
        ...value,
        seo: value?.seo
          ? {
              ...value?.seo,
              setDefault: value?.seo?.type === SEO_TYPES.TEMPLATE,
              ogImage: value?.seo?.ogImage
                ? {
                    ...value?.seo?.ogImage,
                    origin: value?.seo?.ogImage?.original,
                  }
                : this.form?.seo?.ogImage,
            }
          : {
              ...this.form?.seo,
              setDefault: false,
            },
      }
    },
    async createBrand() {
      const loading = this.$loading({
        lock: true,
      })

      const { error } = await delivery.AddeventServiceCore.BrandsActions.create(
        {
          ...this.form,
          setDefault: this.form?.seo?.setDefault,
          seo: this.form?.seo?.setDefault
            ? null
            : {
                ...this.form.seo,
                type: SEO_TYPES.DEFAULT,
                ogImage: {
                  ...this.form?.seo?.ogImage,
                  caption: this.form?.seo?.title,
                },
              },
        },
      )

      loading.close()

      if (error) {
        this.showNotification('Ошибка создания бренда', 'error')
        return
      }

      this.showNotification('Бренд успешно создан', 'success')
    },
    async updateBrand() {
      const loading = this.$loading({
        lock: true,
      })

      const { error } = await delivery.AddeventServiceCore.BrandsActions.update(
        {
          ...this.form,
          setDefault: this.form?.seo?.setDefault,
          seo: this.form?.seo?.setDefault
            ? null
            : {
                ...this.form.seo,
                type: SEO_TYPES.DEFAULT,
                ogImage: {
                  ...this.form?.seo?.ogImage,
                  caption: this.form?.seo?.title,
                },
              },
        },
        this.currentBrandId,
      )

      loading.close()

      if (error) {
        this.showNotification('Ошибка обновления бренда', 'error')
        return
      }

      this.showNotification('Бренд успешно обновлен', 'success')
    },
    async deleteBrand(brand) {
      const isConfirm = confirm(`Вы точно хотите удалить бренд: ${brand.name}`)

      if (!isConfirm) return

      const loading = this.$loading({
        lock: true,
      })

      const { error } = await delivery.AddeventServiceCore.BrandsActions.delete(
        brand.id,
      )

      loading.close()

      if (error === 'Error: ERROR: BRAND DELETE HAVE PRODUCTS') {
        this.showNotification(
          'К сожалению, нельзя удалить бренд, т.к. к нему привязаны продукты',
          'error',
        )
        return
      }

      if (error) {
        this.showNotification('Ошибка удаления бренда', 'error')
        return
      }

      this.showNotification('Бренд успешно удален', 'success')
      await this.getBrandsList()
    },
    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
    handleCreateBrand() {
      this.isModalOpen = true
      this.currentModalType = MODAL_TYPES.CREATE
    },
    async handleUpdateBrand(id) {
      this.currentModalType = MODAL_TYPES.UPDATE
      this.isModalOpen = true
      this.currentBrandId = id

      await this.getBrand(id)
    },
    resetForm() {
      this.form.slug = ''
      this.form.name = ''
      this.form.seo = {
        setDefault: false,
        title: '',
        description: '',
        ogTitle: '',
        ogDescription: '',
        ogImage: {
          id: null,
          caption: '',
          origin: '',
          order: 1,
        },
        keywords: '',
      }
    },
    handleCloseForm() {
      this.isModalOpen = false
      this.resetForm()
    },
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        this.currentModalType === MODAL_TYPES.CREATE
          ? await this.createBrand()
          : await this.updateBrand()

        this.resetForm()
        this.isModalOpen = false
        await this.getBrandsList()
      })
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.AddeventServiceCore.BrandsActions.getList({
          limit: 100,
          page: this.query.page,
          search: queryString,
        })

      if (error) return

      setSearchItems(value.data)
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    justify-content: space-between;
  }

  .pagination {
    @include pagination;
  }

  .dialog {
    .createButton {
      margin-top: 1rem;
    }
  }
}
</style>
